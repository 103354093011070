import React from 'react'
// import { Link } from 'gatsby'

const Button = ({ buttonTitle, href, target, symbol }) => (
  <a href={href} target={target}>
    <button>
      {buttonTitle}
      <div className="symbol">{symbol}</div>
    </button>
  </a>
)

export default Button
