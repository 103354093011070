import React, { useState } from 'react'
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import FsLightbox from 'fslightbox-react';

import './gallery.scss'

export default ({ className = '', style = {} }) => { 
  // if toggler is updated when lightbox is closed it will open it
  // if toggler is updated when lightbox is opened it will close it
  const [toggler, setToggler] = useState(false);

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
    setToggler(!toggler);
  }

  var galleryIndex = 0;

  return (
    <StaticQuery
      query={
        graphql`
          {
            allFile(
              sort: {
                fields: [ childImageSharp___fixed___originalName ], order: ASC
              }
            ) {
              edges {
                node {
                  childImageSharp {
                    fixed(width: 800) {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 250, maxHeight: 250) {
                        ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        `
      }
      render={(data) => { 
  
        return (
          <section className={'Gallery ' + className} style={style}>
            <h1 style={{textAlign: 'center'}}>Gallery</h1>

            {/* GALLERY */}

            <div className="gallery-wrapper" >
              { data.allFile.edges.map( (img, i)  => {
                var min = -5;
                var max = 5;
                var rand = min + Math.random() * (max - min);
                galleryIndex++;
                return (
                  <div tabIndex="0" className="gallery-slide" style={{transform: `rotate(${rand}deg)`, maxWidth: '100%',}} role="button" data-slide={galleryIndex} onClick={ (e) => openLightboxOnSlide(e.currentTarget.getAttribute('data-slide')) } onKeyDown={ (e) => {if (e.keyCode === 13) openLightboxOnSlide(e.currentTarget.getAttribute('data-slide'))}  } >
                    <Img 
                      className="gallery-thumbnail"
                      fluid={img.node.childImageSharp.fluid}
                      style={{ width: 'auto', height: 'auto'}}
                      key={i}
                    />
                  </div>
                )
              })}
            </div>

            {/* LIGHTBOX */}

            <FsLightbox
              customSources={ data.allFile.edges.map( (img, i)  => {
                return ( 
                  <div>
                    <Img
                      className="gallery-image"
                      fixed={img.node.childImageSharp.fixed}
                      key={i}
                    />
                  </div>  
                )
              })}
              toggler={ toggler }
              slide={ lightboxController.slide }
            />
          </section>
        )
      }}
    />
  )
}