import React from 'react'
import './follow-us.scss'
// Components
import FollowUsTruck from './../../components/elements/follow-us-truck/follow-us-truck'
// Images
import ImgFollowUs from './../../assets/images/follow-us/follow-us.svg'
import SymInstagram from './../../assets/images/follow-us/follow-us-instagram.svg'
import SymFacebook from './../../assets/images/follow-us/follow-us-facebook.svg'
import SymReallife from './../../assets/images/follow-us/follow-us-herz.svg'

const FollowUs = ({ className = '', style = {} }) => (
  <section className={'FollowUs ' + className} style={style}>
    <div className="container">
      <div className="ImgFollowUs">
        <ImgFollowUs alt="Follow us!" />
      </div>
      <div className="socialLinks">
        <a
          href="https://www.instagram.com/foodtoura/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link noStyle"
        >
          <SymInstagram /> @foodtoura
        </a>
        <br />
        <a
          href="https://www.facebook.com/Foodtoura-1035475126613833/"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link noStyle"
        >
          <SymFacebook /> foodtoura
        </a>
        <br />
        <span>
          <SymReallife /> and in real life!
        </span>
      </div>
      <div className="ImgFollowUsTruck">
        <FollowUsTruck />
      </div>
    </div>
  </section>
)
export default FollowUs
