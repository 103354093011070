import React from 'react'

import Button from './../elements/interactions/button'
import Arrow from './../../assets/images/symbols/arrow.svg'

const Contact = ({ className = '', style = '' }) => (
  <section className={'Contact ' + className} style={style}>
    <div className="container">
      <h2>schreib´ uns doch mal...</h2>
      <div className="p email">hello@foodtoura.de</div>
      <div className="p">
        <Button
          buttonTitle="E-Mail"
          href="mailto:hello@foodtoura.de"
          symbol={<Arrow />}
        />
      </div>
      <h2>...oder ruf uns an!</h2>
      <div className="p email">0461 - 80 72 85 10</div>
      <div className="p">
        <Button
          buttonTitle="Call Us"
          href="tel:046180728510"
          symbol={<Arrow />}
        />
      </div>
    </div>
  </section>
)
export default Contact
