import React from 'react'

// Components
import FollowUsTruck from './../../components/elements/follow-us-truck/follow-us-truck'

//Styles
import './about-us.scss'
import './follow-us.scss'

//Images
import IconKassette from './../../assets/images/about-us/icon_kassette.svg'
import IconFlasche from './../../assets/images/about-us/icon_flasche.svg'
import IconGabel from './../../assets/images/about-us/icon_gabel.svg'
import IconPommes from './../../assets/images/about-us/icon_pommes.svg'
import IconTruck from './../../assets/images/about-us/icon_truck.svg'
// import HeadlineBG from '../../assets/images/backgroundHeadline.svg'

export default ({className = '', style = {}}) => {
    return (
        <section className={'AboutUs ' + className} style={style} name="about-us">
            {/* <div className="whereweare">
                <h1>Du fragst dich, wo wir uns rumtreiben?</h1>
                <div className="ImgFollowUsTruck"> <FollowUsTruck /> <a className="hier" href="#whereweare">Hier erfährst du's!</a>
                </div>
            </div> */}
            <h1>
                Foodtoura - was ist das eigentlich?
            </h1>
            <div className="aboutus_list">

                <div className="aboutus_list__row">
                    <div className="icon">
                        <IconKassette alt="" />
                    </div>
                    <div className="aboutus_text">
                        Ein motiviertes, buntes Team, das dich auf ­Straßenfesten, Märkten, Festivals 
                        und anderen Veranstaltungen mit ­leckerem, vegetarischem Streetfood versorgt.
                    </div>
                </div>

                <div className="aboutus_list__row">
                    <div className="icon">
                        <IconGabel alt="" />
                    </div>
                    <div className="aboutus_text">
                        Ein Catering mit Rundum-Paket von ­Vorspeise bis Nachtisch, das deinen Geburtstag, 
                        dein Jubiläum oder andere Anlässe zu etwas ganz Besonderem macht.
                    </div>
                </div>

                <div className="aboutus_list__row">
                    <div className="icon">
                        <IconTruck alt="" />
                    </div>
                    <div className="aboutus_text">
                        Die Möglichkeit, dein Event mit unseren ­verschiedenen Fahrzeugen wie Foodtrucks, 
                        Mini-Bar, Crepe- oder Grillstand individuell nach deinen Wünschen zu gestalten.
                    </div>
                </div>

                <div className="aboutus_list__row">
                    <div className="icon">
                        <IconFlasche alt="" />
                    </div>
                    <div className="aboutus_text">
                        Eine herzliche Crew, die Wert auf dich und unsere Umwelt legt.
                    </div>
                </div>

                <div className="aboutus_list__row">
                    <div className="icon">
                        <IconPommes alt="" />
                        {/* <img src="" /> */}
                    </div>
                    <div className="aboutus_text">
                        Qualitatives Essen, mit viel Liebe zubereitet, teilweise selbst hergestellt und 
                        wenn ­möglich aus der Region.
                    </div>
                </div>

            </div>
        </section>
    )
}
