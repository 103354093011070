import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby'
import { ElementsWrapper } from 'react-scrolling-nav';

// Components
import Layout from '../components/layouts/default'
import Gallery from './../components/blocks/gallery'
import Contact from './../components/blocks/contact'
import FollowUs from './../components/blocks/follow-us'
import AboutUs from './../components/blocks/about-us'
import OurFood from './../components/blocks/our-food'
import WhereWeAre from './../components/blocks/whereweare'
// Images
import heroimage from './../assets/images/foodtoura-header.jpg'



class IndexPage extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
        {
          site {
            siteMetadata {
              navbarItems {
                label
                target
              }
            }
          }
        }
        `}
        render={data => (
          <Layout>
            <div className="IndexPage">
              <img src={heroimage} style={{ display: 'block', width: '100%' }} alt="Foodtoura Foodtruck" /> 
              <ElementsWrapper items={data.site.siteMetadata.navbarItems}>
                  <AboutUs name="about-us" /> 
                  <Gallery name="gallery" />
                  <OurFood name="our-food" />
                  {/* <WhereWeAre name="whereweare" /> */}
                  <Contact name="contact" style={{ textAlign: 'center', fontSize: '1.2em' }} />
                  <FollowUs name="follow-us" className="inverted" style={{ textAlign: 'center' }} />
              </ElementsWrapper>
            </div>
          </Layout>
        )}
      />      
    );
  }
}

export default IndexPage;
