import React from 'react'

//Styles
import './our-food.scss'

//Images
// import HeadlineBG from '../../assets/images/backgroundHeadline.svg'
import IconBurger from '../../assets/images/our-food/icon_burger.svg'
import IconDips from '../../assets/images/our-food/icon_dipps.svg'
import IconWrap from '../../assets/images/our-food/icon_wrap.svg'
import IconFalafel from '../../assets/images/our-food/icon_falafel.svg'
import IconPommes from './../../assets/images/about-us/icon_pommes.svg'
import IconMild from './../../assets/images/our-food/icon_tropfen.svg'
import IconScharf from './../../assets/images/our-food/icon_scharf.svg'

export default ({className = '', style = {}}) => {
    return (
        <section className={'OurFood ' + className} style={style}>
            <h1>
                Unsere Speisen
            </h1>
            <div className="ourfood_list">
            {/* //FRITTEN------------------------------- */}
            <div className="foodBlock">
                <div className="headline">
                    <IconPommes alt="" className="icon"/>
                </div>
                <h2>
                    Fritten
                    <h4 className="headline_small">
                        auch als Süßkartoffel-fritten
                    </h4>
                </h2>
                <h2 className="nomargin">Classic</h2>
                <h4 className="bluefont">Ketchup und Mayo wahlweise dazu</h4>

                <h2 className="nomargin">Hot & Spicy</h2>
                <h4 className="bluefont">Homemade Sauce, Jalapeños, Käse, Lauchzwiebeln</h4>
                
                <h2 className="nomargin">skandi</h2>
                <h4 className="bluefont">Remoulade, Saure Gurken, 
                Röstzwiebeln</h4>
                
                <h2 className="nomargin">Frühlingszauber</h2>
                <h4 className="bluefont">Schmand, Lauchzwiebeln, Salz, 
                Pfeffer</h4>
            </div>
            {/* //WRAPS------------------------------- */}
            <div className="foodBlock">
                <div className="headline">
                    <IconWrap alt="" className="icon"/>
                </div>
                <h2 className="margin-bottom">
                    Wraps
                </h2>
                <h2 className="nomargin">Homemade</h2>
                <h4 className="bluefont">Falafel, Salat, Gurke, Tomate, Zwiebeln, Homemade Sauce
                </h4>

                <h2 className="nomargin">Joghurt-Minze</h2>
                <h4 className="bluefont">Falafel, Salat, Gurke, Tomate, Zwiebeln, Joghurt-Minz-Sauce</h4>
                
                <h2 className="nomargin">Joghurt-Minze + Homemade</h2>
                <h4 className="bluefont">Falafel, Salat, Gurke, Tomate, Zwiebeln, Homemade Sauce, Joghurt-Minz-Sauce</h4>
            </div>
           {/* //BURGER------------------------------- */}
            <div className="foodBlock">
                <div className="headline">
                    <IconBurger alt="" className="icon"/>
                </div>
                <h2>
                    Burger
                    <h4 className="headline_small">
                        auch vegan erhältlich
                    </h4>
                </h2>
                <h2 className="nomargin">Freshly Ground</h2>
                <h4 className="bluefont">Kidney Patty, Zwiebeln, Tomate, Salat, Gurke, Knoblauch Sauce, Mayo
                </h4>

                <h2 className="nomargin">Spicy Home</h2>
                <h4 className="bluefont">Kidney Patty, Röstzwiebeln, Tomate, Salat, Gurke, Homemade Sauce, Smokey Hot Cocktail Sauce</h4>
            </div>
            {/* //FALAFEL------------------------------- */}
                <div className="foodBlock">
                    <div className="headline">
                        <IconFalafel alt="" className="icon"/>
                    </div>
                    <h2>
                        Falafel
                        <h4 className="headline_small">
                            auch mit Süßkartoffel-fritten
                        </h4>
                    </h2>
                    <h2 className="nomargin">Vier Falafel</h2>
                <h4 className="bluefont">Einzelne Falafel mit Sauce nach Wahl</h4>

                <h2 className="nomargin">Falafel-Teller</h2>
                <h4 className="bluefont">Falafel, Fritten, Salatbeilage, zwei Saucen nach Wahl</h4>
                </div>
            {/* //DIPS------------------------------- */}
                <div className="foodBlock_dip">
                    <div className="headline">
                        <IconDips alt="" className="icon"/>
                    </div>
                    <h2>
                        Dips
                    </h2>
                    <div className="dipBlock">
                        <div className="dips"><IconMild alt="" className="icon_sm"/><h4>Joghurt-Minze</h4></div>
                        <div className="dips"><IconMild alt="" className="icon_sm"/><h4>Ketchup</h4></div>
                        <div className="dips"><IconMild alt="" className="icon_sm"/><h4>Schmand</h4></div>
                        <div className="dips"><IconMild alt="" className="icon_sm"/><h4>Remoulade</h4></div>
                        <div className="dips"><IconScharf alt="" className="icon_sm"/><h4>Homemade</h4></div>
                        <div className="dips"><IconMild alt="" className="icon_sm"/><h4>Knoblauch</h4></div>
                        <div className="dips"><IconScharf alt="" className="icon_sm"/><h4>SmokeyCocktail</h4></div>
                        <div className="dips"><IconMild alt="" className="icon_sm"/><h4>Mayonnaise</h4></div>
                    </div>
                </div>
            </div>
        </section>
    )
}
